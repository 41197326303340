//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

$buttonHeight: 50px;

.progressBarWrapper
{
    position: absolute;
    width:    100%;
    left:     0;
    bottom:   0;

    .progressBar
    {
        height:     3px;
        background: $colorTurquoise;
    }
}

.wrapper
{
    height:        $buttonHeight;
    border-radius: 8px;
    overflow:      hidden;
    font-size:     15px;
    color:         $colorGrayLighter3;
    position:      relative;
    background:    $colorGrayLighter4;
    cursor:        pointer;
    user-select:   none;
    word-break:    break-all;

    &.wrapperUploading
    {
        pointer-events: none;
    }

    .iconWrapper
    {
        position:        absolute;
        top:             0;
        left:            0;
        background:      $colorGrayLighter4;
        width:           $buttonHeight;
        height:          $buttonHeight;
        display:         flex;
        align-items:     center;
        justify-content: center;
        transition:      background-color $defaultEffectAnimationSpeed linear;

        &.iconWrapperError
        {
            background-color: $colorRed;

            svg
            {
                *
                {
                    stroke: $colorWhite;
                }
            }
        }

        &.iconWrapperUploaded
        {
            background-color: $colorTurquoise;

            svg
            {
                *
                {
                    stroke: $colorBlack;
                }
            }
        }

        &.iconWrapperUploading
        {

        }

        svg
        {
            *
            {
                stroke: $colorGrayLighter3;
            }
        }
    }

    strong
    {
        height:          $buttonHeight;
        padding:         0 15px 0 62px;
        display:         flex;
        justify-content: flex-start;
        align-items:     center;
        z-index:         2;
        overflow:        hidden;
        text-overflow:   ellipsis;
        width:           100%;
    }

    .deleteOverlay
    {
        z-index:    1;
        position:   absolute;
        top:        0;
        left:       0;
        width:      100%;
        height:     100%;
        background: $colorTurquoise;
        opacity:    0;
        transition: opacity $defaultEffectAnimationSpeed linear;
        display:    flex;

        .deleteIconWrapper
        {
            width:           50px;
            height:          $buttonHeight;
            display:         flex;
            align-items:     center;
            justify-content: center;
            position:        absolute;
            top:             0;
            left:            0;

            .deleteIconCircle
            {
                $circleSize:     38px;

                width:           $circleSize;
                height:          $circleSize;
                background:      $colorBlack;
                border-radius:   $circleSize;
                display:         flex;
                align-items:     center;
                justify-content: center;

                svg
                {
                    width: 20px;

                    *
                    {
                        stroke: $colorWhite;
                    }
                }
            }
        }

        strong
        {
            color: $colorBlack;
        }
    }

    &:hover
    {
        .deleteOverlay
        {
            opacity: 1;
        }
    }
}