//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fontWeights';
@import '../../../styles/zIndexes';

.wrapper
{
    min-width: fit-content;

    .wrapperInner
    {
        padding:       35px;
        border:        1px solid $colorGrayLighter8;
        border-radius: 8px;
        color:         $colorGrayLighter9;
        text-align:    center;

        h3
        {
            font-size:   20px;
            font-weight: $fontWeightSemiBold;
            margin:      0 0 10px 0;
            padding:     0;

            span
            {
                color: $colorTurquoise;
            }
        }

        p
        {
            font-size: 15px;
            margin:    0 0 30px 0;
        }

        .buttonWrapper
        {
            display: inline-block;
        }
    }
}
