//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

$trackHeight: 1px;

.handles
{
    .handle
    {
        $size:          22px;

        position:       absolute;
        background:     $colorGrayLight2;
        width:          $size;
        height:         $size;
        border-radius:  $size;
        border-width:   2px;
        border-style:   solid;
        transform:      translate(-50%, -50%);
        pointer-events: none;
        z-index:        $zIndexSliderHandle;
        transition:     border-color $defaultEffectAnimationSpeed linear;

        &.handleDefault
        {
            border-color: $colorTurquoise;
        }

        &.handleUnimportant
        {
            border-color: $colorGrayLighter2;
        }
    }
}

.rail
{
    height:    20px;
    width:     100%;
    position:  absolute;
    transform: translate(0%, -50%);
    z-index:   $zIndexSliderRail;
    cursor:    pointer;

    .railInner
    {
        background-color: $colorGrayLighter7;
        z-index:          $zIndexSliderRailInner;
        height:           1px;
        pointer-events:   none;
        transform:        translate(0%, -50%);
        position:         absolute;
        width:            100%;
        top:              10px;
    }
}

.tracks
{
    .track
    {
        height:         1px;
        position:       absolute;
        transform:      translate(0%, -50%);
        pointer-events: none;
        z-index:        $zIndexSliderTrack;
        transition:     background-color $defaultEffectAnimationSpeed linear;

        &.trackDefault
        {
            background: $colorTurquoise;
        }

        &.trackUnimportant
        {
            background: $colorGrayLighter2;
        }
    }
}

.wrapper
{
    position: relative;
}