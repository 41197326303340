//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fontWeights';
@import '../../../styles/zIndexes';

$sidePadding: 20px;

.filesWrapper
{

}

.footer
{
    justify-content: center;
    align-items:     center;
    display:         flex;
    padding:         10px 0 0 0;

    .footerContent
    {
        display: inline-block;
    }
}

.wrapper
{
    &.wrapperSmall
    {
        width: 300px;
    }

    h4
    {
        color:       $colorGrayLighter6;
        font-size:   16px;
        font-weight: $fontWeightSemiBold;
        padding:     0 0 0 $sidePadding;
        margin:      0 0 16px 0;
    }
}