//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

$overlayWidth: 750px;

.closeButton
{
    $size:           40px;

    width:           $size;
    height:          $size;
    border-radius:   $size;
    background:      $colorGrayLighter2;
    display:         flex;
    align-items:     center;
    justify-content: center;
    position:        absolute;
    z-index:         $zIndexModalOverlayPaneOpenCloseButton;
    cursor:          pointer;
    transition:      background-color $defaultEffectAnimationSpeed linear;

    @media (max-width: $overlayWidth)
    {
        top:   -10px !important;
        right: 10px !important;
    }

    &:hover
    {
        background-color: $colorTurquoise;

        svg
        {
            *
            {
                stroke: $colorBlack;
            }
        }
    }

    svg
    {
        *
        {
            stroke: $colorGrayLighter3;
        }
    }
}
