//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fontWeights';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

.tab
{
    color:         $colorGrayLighter2;
    font-size:     16px;
    min-height:    50px;
    line-height:   54px;
    white-space:   nowrap;
    margin:        0 50px 0 0;
    position:      relative;
    bottom:        0;
    cursor:        pointer;
    border-bottom: 1px solid $colorGrayLighter2;
    transition:    color $defaultEffectAnimationSpeed linear,
                   border-color $defaultEffectAnimationSpeed linear;

    &:hover
    {
        color:        $colorGrayLighter4;
        border-color: $colorGrayLighter4;
    }

    &.tabActive
    {
        color:        $colorTurquoise;
        border-color: $colorTurquoise;
    }
}

.wrapper
{
    border-bottom: 1px solid $colorGrayLighter2;
    display:       flex;
    overflow-x:    auto;
    margin:        0 0 49px 0;

    &::-webkit-scrollbar
    {
        width:      0;
        display:    none;
        background: transparent;
    }
}
