//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

$colorBlack:        #000000;
$colorBlack50:      rgba(0, 0, 0, 0.5);
$colorBlack80:      rgba(0, 0, 0, 0.8);
$colorBlack86:      rgba(0, 0, 0, 0.86);
$colorBlack90:      rgba(0, 0, 0, 0.90);
$colorGray:         #111213;
$colorGrayDark:     #18191a;
$colorGrayDark80:   rgba(24, 25, 26, 0.8);
$colorGrayLight:    #232425;
$colorGrayLight2:   #1f2021;
$colorGrayLighter:  #393b3c;
$colorGrayLighter2: #373839;
$colorGrayLighter3: #b0b3b8;
$colorGrayLighter4: #4d4e4f;
$colorGrayLighter5: #d5d6da;
$colorGrayLighter6: #c1c3c8;
$colorGrayLighter7: #3a3b3c;
$colorGrayLighter8: #979797;
$colorGrayLighter9: #f6f6f6;
$colorRed:          #d1000d;
$colorRedLight:     #f1828d;
$colorTransparent:  transparent;
$colorTurquoise:    #00ffd0;
$colorWhite:        #ffffff;

:export
{
    colorBlack:        $colorBlack;
    colorGray:         $colorGray;
    colorGrayDark:     $colorGrayDark;
    colorGrayLight:    $colorGrayLight;
    colorGrayLight2:   $colorGrayLight2;
    colorGrayLighter:  $colorGrayLighter;
    colorGrayLighter2: $colorGrayLighter2;
    colorGrayLighter3: $colorGrayLighter3;
    colorGrayLighter4: $colorGrayLighter4;
    colorGrayLighter5: $colorGrayLighter5;
    colorGrayLighter6: $colorGrayLighter6;
    colorGrayLighter7: $colorGrayLighter7;
    colorRed:          $colorRed;
    colorTransparent:  $colorTransparent;
    colorTurquoise:    $colorTurquoise;
    colorWhite:        $colorWhite;
}
