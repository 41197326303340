//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/zIndexes';

.inputWrapper
{
    flex:      1;
    display:   flex;
    flex-flow: row wrap;
    margin:    0 -4px;

    > div
    {
        flex:   1 0 150px; // This will make the child inputs grow to the full width
        margin: 0 4px;
    }
}

.sliderWrapper
{
    padding: 30px 0 40px 0;
}

.wrapper
{
    display:        flex;
    flex-direction: column;

    &.alignInput
    {
        padding-right: 50px;
    }
}
