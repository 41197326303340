//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fontWeights';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

.text
{
    color:       $colorGrayLighter7;
    font-size:   16px;
    font-weight: $fontWeightRegular;
    cursor:      pointer;
    transition:  color $defaultEffectAnimationSpeed linear;

    &:hover
    {
        color: $colorTurquoise;
    }
}

.wrapper
{

}
