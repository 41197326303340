//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fontWeights';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

.textWrapper
{
    &,
    a
    {
        font-size:       14px;
        font-weight:     $fontWeightRegular;
        color:           $colorGrayLighter3;
        text-decoration: none;
        cursor:          pointer;
    }

    .textWrapperInner
    {
        display: block;
        padding: 12px 19px;
    }

    strong
    {
        font-weight: $fontWeightSemiBold;
        font-size:   24px;
        margin:      0 0 1px 0;
        display:     inline-block;
    }
}

.textWrapperDefault
{
    strong
    {
        color: $colorWhite;
    }
}

.textWrapperHighlighted
{
    strong
    {
        color: $colorTurquoise;
    }
}

.wrapper
{
    transition: background-color $defaultEffectAnimationSpeed linear;

    &:hover
    {
        background-color: $colorGrayLighter2;
    }
}