//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fontWeights';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

.wrapper
{
    $borderRadius:   10px;

    height:          50px;
    font-size:       15px;
    display:         flex;
    align-items:     center;
    font-weight:     $fontWeightSemiBold;
    justify-content: center;
    flex:            1;
    cursor:          pointer;
    border-width:    2px;
    user-select:     none;
    border-style:    solid;
    transition:      background-color $defaultEffectAnimationSpeed linear,
                     color $defaultEffectAnimationSpeed linear;

    &:first-child
    {
        border-bottom-left-radius: $borderRadius;
    }

    &:last-child
    {
        border-bottom-right-radius: $borderRadius;
    }

    &.wrapperStandAlone
    {
        border-radius: $borderRadius;
    }

    &.wrapperMultipleStandAlone
    {
        &:first-child
        {
            border-top-right-radius:    0;
            border-bottom-right-radius: 0;
        }

        &:last-child
        {
            border-top-left-radius:    0;
            border-bottom-left-radius: 0;
        }
    }

    &.wrapperDisabled
    {
        cursor: not-allowed;
        filter: brightness(20%);
    }

    &.wrapperGray
    {
        background:   $colorGrayLighter7;
        border-color: $colorGrayLighter7;
        color:        $colorWhite;

        &:hover
        {
            background: $colorGray;
        }
    }

    &.wrapperTurquoise
    {
        background:   $colorTurquoise;
        border-color: $colorTurquoise;

        &:hover
        {
            background: $colorGray;
            color:      $colorTurquoise;
        }
    }
}
