//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fontWeights';
@import '../../../styles/zIndexes';

$sidePadding: 20px;

.file
{
    background:    $colorGrayLighter2;
    border-radius: 10px;
    margin:        0 0 11px 0;
    padding:       5px 0;
    min-height:    51px;
    display:       flex;
    flex-flow:     row wrap;
    align-items:   center;
    color:         $colorGrayLighter6;
    font-size:     14px;
    overflow:      hidden;

    &:last-child
    {
        margin-bottom: 0;
    }

    .filePreview
    {
        flex:            0 0 51px;
        height:          51px;
        display:         flex;
        align-items:     center;
        justify-content: center;

        svg
        {
            *
            {
                stroke: $colorGrayLighter6;
            }
        }

        .filePreviewImageWrapper
        {
            width:  51px;
            height: 51px;

            img
            {
                width:      100%;
                height:     100%;
                object-fit: cover;
            }
        }
    }

    .fileCenter,
    .fileLeft,
    .fileRight
    {
        &.fileCenter
        {
            flex:        1;
            font-weight: $fontWeightSemiBold;
            margin:      0 10px;
        }

        &.fileLeft
        {
            flex:        1;
            font-weight: $fontWeightRegular;
            padding:     0 0 0 $sidePadding;

            span
            {
                -moz-box-orient:    vertical;
                -webkit-box-orient: vertical;
                box-orient:         vertical;
                display:            inline-block;
                -moz-line-clamp:    2;
                -webkit-line-clamp: 2;
                line-clamp:         2;
                overflow:           hidden;
                display:            -webkit-box;
            }
        }

        &.fileRight
        {
            flex-shrink: 1;
            padding:     0 $sidePadding/2 0 0;
            margin-left: auto;
        }
    }
}