//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/zIndexes';

.spacer
{
    display:    inline-block;
    visibility: hidden;

    &.visible
    {
        color:           $colorWhite;
        align-items:     center;
        justify-content: center;
        visibility:      visible;
    }

    &.lines
    {
        &::before,
        &::after
        {
            content:       '';
            width:         100%;
            max-width:     120px;
            margin:        0 30px;
            border-bottom: 2px solid $colorTurquoise;
        }
    }
}
