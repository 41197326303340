//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fontWeights';
@import '../../../styles/breakpoints';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

.iconWrapper
{
    margin-right: 21px;
}

.link
{
    display:         flex;
    flex-direction:  row;
    text-decoration: none;
    font-size:       15px;
    font-weight:     $fontWeightSemiBold;
    cursor:          pointer;
    color:           $colorGrayLighter3;
    padding:         0 0 0 75px;
    height:          50px;
    border-right:    4px solid $colorGrayLight2;
    border-radius:   3px;
    overflow:        hidden;
    white-space:     nowrap;
    transition:      background-color $defaultEffectAnimationSpeed linear,
                     border-color $defaultEffectAnimationSpeed linear,
                     color $defaultEffectAnimationSpeed linear;
    line-height:     50px;
    align-items:     center;

    &:hover
    {
        background-color: $colorGrayLighter4;
        border-color:     $colorGrayLighter4;
    }

    &.reducedPadding
    {
        padding: 0 0 0 20px;
    }

    &:active,
    &.linkActive
    {
        color:            $colorTurquoise;
        background-color: $colorGrayLighter2;
        border-color:     $colorTurquoise;

        svg
        {
            *
            {
                stroke: $colorTurquoise;
            }
        }
    }

    svg
    {
        *
        {
            stroke: $colorGrayLighter3;
        }
    }
}

.wrapper
{
    margin-bottom: 1px;

    &:last-child
    {
        margin-bottom: 0;
    }
}