//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/fonts';
@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

.screenTooSmallOverlay
{
    position:       absolute;
    top:            0;
    left:           0;
    width:          100vw;
    height:         100vh;
    overflow:       hidden;
    flex-direction: column;
    z-index:        $zIndexScreenTooSmallOverlay;
    background:     $colorGrayLight2;
    padding:        28px 22px;
    box-sizing:     border-box;
    display:        flex;
    min-width:      320px;

    @media only screen and (min-width: 1200px)
    {
        display: none;
    }

    .screenTooSmallLogo
    {
        display:          block;
        text-indent:      -13371337px;
        overflow:         hidden;
        width:            120px;
        height:           14px;
        cursor:           pointer;
        background-image: url('../../../assets/images/framebutler-logo-header.svg');
        background-size:  cover;
    }

    .screenTooSmallTextWrapper
    {
        font-family: $fontSourceGeneralBody;
        color:       $colorWhite;
        margin-top:  78px;
        max-width:   500px;

        h1
        {
            font-size:     36px;
            line-height:   40px;
            margin-bottom: 17px;
        }

        p
        {
            font-size:   18px;
            line-height: 26px;
        }
    }
}
