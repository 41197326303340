//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/breakpoints';
@import '../../../styles/zIndexes';

.image
{
    width:               calc(50% - 10px);
    height:              0;
    padding-top:         calc(50% - 10px);
    position:            relative;
    background-position: center;
    background-size:     cover;
    margin:              0 5px 10px 5px;
    display:             flex;
    align-items:         center;
    justify-content:     center;
    box-sizing:          border-box;

    @media (min-width: $screen-md)
    {
        width:       calc(100% / 3 - 10px);
        padding-top: calc(100% / 3 - 10px);
    }
}

.imageWrapper
{
    display:   flex;
    flex-wrap: wrap;
    margin:    0 -35px;
}

.wrapper
{
    background:                 $colorGrayDark;
    display:                    flex;
    justify-content:            center;
    align-items:                center;
    position:                   relative;
    border-bottom-left-radius:  10px;
    border-bottom-right-radius: 10px;
    padding:                    30px;

    .wrapperInner
    {
        width:     $profileContentWidth;
        max-width: 100%;
    }
}
