//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fontWeights';
@import '../../../styles/breakpoints';
@import '../../../styles/zIndexes';

.rowWrapper
{

}

.shortFactsWrapper
{
    display:        flex;
    flex-direction: column;
    padding:        50px 20px 20px 20px;

    @media (min-width: $screen-md)
    {
        padding: 50px 90px 20px 90px;
    }

    h4
    {
        color:       $colorWhite;
        font-size:   15px;
        font-weight: $fontWeightSemiBold;
        height:      40px;
        margin:      0;
        padding:     0;
    }
}

.textGroupWrapper
{
    border-top: 1px solid $colorGrayLighter7;
    margin:     20px 0 0 0;
    padding:    35px 20px 10px 20px;

    @media (min-width: $screen-md)
    {
        padding: 35px 90px 10px 90px;
    }
}