//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/fontWeights';
@import '../../../styles/variables';

.fileUploadOverlay
{
    position: absolute;
    top:      0;
    left:     0;
    width:    100%;
    height:   100%;
    cursor:   pointer;
    outline:  none;
    opacity:  0.000001;
}

.wrapper
{
    position: relative;
}