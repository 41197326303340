//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/fontWeights';
@import '../../../styles/variables';

.button
{
    text-align:      center;
    cursor:          pointer;
    display:         flex;
    justify-content: center;
    border-radius:   50px;
    user-select:     none;
    text-decoration: none;
    align-items:     center;
    padding:         0 20px;
    box-sizing:      border-box;
    border-width:    2px;
    border-style:    solid;
    white-space:     nowrap;
    transition:      border-color $defaultEffectAnimationSpeed linear,
                     background-color $defaultEffectAnimationSpeed linear,
                     color $defaultEffectAnimationSpeed linear;

    &.buttonDisabled
    {
        border-color: $colorGrayLighter7 !important;
        cursor:       not-allowed;

        &:hover
        {
            background-color: transparent !important;

            .text
            {
                color: $colorGrayLighter7;
            }

            svg
            {
                *
                {
                    stroke: $colorGray;
                }
            }
        }

        svg
        {
            *
            {
                stroke: $colorGrayLighter7 !important;
            }
        }
    }

    &.buttonGray
    {
        border-color:     $colorGrayLighter7;
        height:           50px;
        background-color: $colorGrayLighter7;

        &:hover
        {
            background-color: $colorTurquoise;

            .text
            {
                color: $colorBlack;
            }

            svg
            {
                *
                {
                    stroke: $colorBlack;
                }
            }
        }

        svg
        {
            *
            {
                stroke: $colorGrayLighter6;
            }
        }
    }

    &.buttonGrayGhost
    {
        border-color: $colorGrayLighter6;
        height:       50px;

        &:hover
        {
            background-color: $colorGrayLighter6;

            .text
            {
                color: $colorGray;
            }

            svg
            {
                *
                {
                    stroke: $colorGray;
                }
            }
        }

        svg
        {
            *
            {
                stroke: $colorGrayLighter6;
            }
        }
    }

    &.buttonGrayWithGreenIcon
    {
        &:hover
        {
            svg
            {
                *
                {
                    stroke: $colorBlack !important;
                }
            }
        }

        svg
        {
            *
            {
                stroke: $colorTurquoise !important;
            }
        }
    }

    &.buttonTurquoiseGhost,
    &.buttonTurquoiseGhostSmall
    {
        border-color: $colorTurquoise;

        &.buttonTurquoiseGhost
        {
            height: 50px;
        }

        &.buttonTurquoiseGhostSmall
        {
            height: 35px;
        }

        &.buttonTurquoiseGhostWithDarkBackground
        {
            background: $colorBlack50;

            .text
            {
                color:          $colorTurquoise;
                text-transform: uppercase;
            }
        }

        &:hover
        {
            background-color: $colorTurquoise;

            .text
            {
                color: $colorGray;
            }

            svg
            {
                *
                {
                    stroke: $colorGray;
                }
            }
        }

        svg
        {
            *
            {
                stroke: $colorTurquoise;
            }
        }
    }

    &.buttonWithWhiteSpaces
    {
        white-space: pre-wrap;
        line-height: 16px;
    }
}

.icon
{
    margin-right: 10px;

}

.buttonTurquoiseGhostSmall
{
    .icon
    {
        width:        18px !important;
        margin-right: 4px;
    }
}

.text
{
    font-size:   16px;
    font-weight: $fontWeightRegular;
    transition:  color $defaultEffectAnimationSpeed linear;

    &.textDisabled
    {
        color: $colorGrayLighter7 !important;
    }

    &.textGray
    {
        color: $colorGrayLighter3;
    }

    &.textGrayGhost
    {
        color: $colorGrayLighter6;
    }

    &.textTurquoiseGhost,
    &.textTurquoiseGhostSmall
    {
        color: $colorTurquoise;
    }

    &.textWithWhiteSpaces
    {
        text-align: left;
        font-size:  15px;
    }
}
