//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fontWeights';
@import '../../../styles/zIndexes';

.progressBar
{
    $borderRadius: 3px;

    background:    $colorGray;
    border-radius: $borderRadius;
    overflow:      hidden;
    height:        10px;

    .progress
    {
        background:    $colorTurquoise;
        height:        100%;
        border-radius: $borderRadius;
    }
}

.wrapper
{
    user-select: none;
    flex:        1;

    h5
    {
        font-size:   15px;
        font-weight: $fontWeightRegular;
        color:       $colorWhite;
        margin:      0 0 8px 0;
    }
}