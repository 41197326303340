//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/fontWeights';
@import '../../../styles/breakpoints';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

$sidePadding: 15px;

.errorWrapper
{

}

.input
{
    background:  $colorTransparent;
    color:       $colorGrayLighter3;
    border:      0;
    height:      50px;
    box-sizing:  border-box;
    width:       100%;
    font-size:   15px;
    font-weight: $fontWeightRegular;
    padding:     0 $sidePadding;
    resize:      none;
    font-family: $fontSourceGeneralBody;
    outline:     none;

    &::placeholder
    {
        color: $colorGrayLighter3;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button
    {
        -webkit-appearance: none;
        margin:             0;
    }

    &::-webkit-calendar-picker-indicator
    {
        display: none;
    }

    &[type=date],
    &[type=number],
    {
        -moz-appearance: textfield;
    }

    &.multiLine
    {
        padding-top:    $sidePadding;
        padding-bottom: $sidePadding;
        min-height:     204px;
        resize:         vertical;
    }
}

.unit
{
    color:       $colorGrayLighter3;
    font-size:   15px;
    font-weight: $fontWeightRegular;
    padding:     0 15px;
    display:     flex;
    align-items: center;
    opacity:     .5;

    &.unitPostfix
    {
        padding-left: 0;
    }

    &.unitPrefix
    {
        padding-right: 0;
    }

    &.unitMultiLine
    {
        align-items: flex-start;
        padding-top: $sidePadding;
        max-width:   100px;
        display:     none;

        @media (min-width: $screen-md)
        {
            display: flex;
        }
    }
}

.wrapper
{
    display:            flex;
    margin:             0 0 14px 0;
    background:         $colorGrayLighter7;
    border-radius:      3px;
    flex:               1;
    border:             1px solid $colorGrayLighter7;
    border-right-width: 3px !important;
    transition:         border-color $defaultEffectAnimationSpeed linear,
                        border-widht $defaultEffectAnimationSpeed linear;

    &.wrapperIsChild
    {
        margin-bottom: 0;
    }

    &.wrapperNoMarginBottom
    {
        margin-bottom: 0;
    }

    &:hover
    {
        border-color: $colorGrayLighter3;
    }

    &:active,
    &:focus,
    &.wrapperActive
    {
        border-color: $colorTurquoise;
    }

    &.wrapperError
    {
        border-color: $colorRed;
    }

    &.wrapperHasContent
    {
        border-right-color: $colorTurquoise;
    }

    &.hidden
    {
        display: none;
    }

    &.alignInput
    {
        margin-right: 50px;
    }
}
