//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fontWeights';
@import '../../../styles/breakpoints';
@import '../../../styles/zIndexes';

.additionalContent
{
    border-top: 1px solid $colorGrayLighter;
    padding:    20px 82px;
}

.body
{
    padding: 20px 42px;

    @media (min-width: $screen-md)
    {
        padding: 20px 82px;
    }

    .bodyInner
    {
        max-width:   504px;
        font-size:   14px;
        color:       $colorGrayLighter5;
        font-weight: $fontWeightRegular;
        line-height: 18px;
        white-space: pre-wrap;
    }

    .attachmentWrapper
    {
        margin: 20px 0 0 0;
    }
}

.head
{
    padding:       15px;
    display:       flex;
    flex-flow:     row wrap;
    align-items:   center;
    border-bottom: 1px solid $colorGrayLighter;

    .headLeft
    {
        flex:    1;
        display: flex;

        h3
        {
            margin:          0;
            font-weight:     $fontWeightSemiBold;
            font-size:       18px;
            color:           $colorWhite;
            display:         flex;
            align-items:     center;
            justify-content: center;
            white-space:     nowrap;
        }
    }

    .headRight
    {
        flex:          1;
        text-align:    right;
        color:         $colorWhite;
        font-size:     16px;
        font-weight:   $fontWeightSemiBold;
        padding-right: 13px;
        white-space:   nowrap;
        margin-left:   20px;
    }

    .avatarWrapper
    {
        margin: 4px 14px 0 0;
    }
}

.wrapper
{
    background:    $colorGrayLight2;
    border-radius: 10px;
    margin:        0 0 17px 0;

    &.wrapperIncoming
    {
        margin-right: 73px;
    }

    &.wrapperOutgoing
    {
        margin-left: 73px;
    }
}