//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

.reference
{
    width:               320px;
    height:              180px;
    position:            relative;
    background-position: center;
    background-size:     cover;
    margin-bottom:       10px;
    display:             flex;
    align-items:         center;
    justify-content:     center;

    &:nth-child(2n+1)
    {
        margin-right: 10px;
    }
}

.referenceWrapper
{
    display:   flex;
    flex-wrap: wrap;
}

.wrapper
{
    background:      $colorGrayDark;
    padding:         30px;
    display:         flex;
    justify-content: center;
    align-items:     center;
    position:        relative;

    .wrapperInner
    {
        width:     $profileContentWidth;
        max-width: 100%;
    }
}