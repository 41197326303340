//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fontWeights';
@import '../../../styles/breakpoints';
@import '../../../styles/zIndexes';

.left,
.right
{
    h4
    {
        color:       $colorWhite;
        font-size:   15px;
        font-weight: $fontWeightSemiBold;
        height:      40px;
        margin:      0;
        padding:     0;
    }

    &.left
    {

    }

    &.right
    {
        margin-top: 20px;

        @media (min-width: $screen-md)
        {
            margin-top: 0;
            padding:    0 0 0 100px;
        }
    }
}

.wrapper
{
    display:         flex;
    flex-flow:       column nowrap;
    justify-content: space-between;
    padding:         50px 20px 20px 20px;

    @media (min-width: $screen-md)
    {
        flex-flow: row nowrap;
        padding:   50px 90px 20px 90px;
    }
}