//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fontWeights';
@import '../../../styles/zIndexes';

.bottomRow
{
    .fileListWrapper
    {
        > div
        {
            margin-bottom: 15px;

            &:last-child
            {
                margin-bottom: 0;
            }
        }
    }
}

.errorWrapper
{
    padding: 8px 0 0 0;
}

.tip
{
    font-size:   12px;
    color:       $colorGrayLighter3;
    height:      50px;
    display:     flex;
    align-items: center;
    padding:     0 0 0 15px;
}

.topRow
{
    flex-direction: row;
    display:        flex;
    margin:         0 0 16px 0;
}

.wrapper
{

}