//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/colors';
@import '../../styles/fontWeights';
@import '../../styles/zIndexes';

.background
{
    background-image:    url('../../assets/images/login-background.jpg');
    background-repeat:   no-repeat;
    background-size:     cover;
    background-position: center center;
    height:              100%;
    position:            relative;
}

.text
{
    background: $colorGray;
    color:      $colorWhite;
    float:      left;
    padding:    5px 15px;

    &:first-child
    {
        padding-top: 10px;
    }

    &:last-child
    {
        padding-bottom: 10px;
    }
}

.textWrapper
{
    font-size:   60px;
    line-height: 62px;
    font-weight: $fontWeightSemiBold;
    position:    absolute;
    top:         50%;
    left:        170px;
    width:       600px;
    transform:   translateY(-50%);
}

.wrapper
{
    height: 100%;
}
