//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

.bottomWrapper
{
    background:      $colorGrayDark;
    padding:         30px;
    display:         flex;
    justify-content: center;
    align-items:     center;
    position:        relative;

    .bottomWrapperInner
    {
        width:      $profileContentWidth;
        color:      $colorWhite;
        max-width:  100%;
        text-align: center;

        h3
        {
            font-weight: normal;
            font-size:   22px;
        }
    }
}

.topWrapper
{
    height:                  240px;
    background-position:     center;
    background-size:         cover;
    border-top-left-radius:  10px;
    border-top-right-radius: 10px;
    display:                 flex;
    justify-content:         center;
    align-items:             center;
}

.editButtonWrapper
{
    position:  absolute;
    z-index:   $zIndexAbsoluteEditButton;
    top:       calc(100% - 30px);
    right:     30px;
    transform: translateY(-100%);

    > div
    {
        background-color: $colorGrayDark80;
    }
}

.wrapper
{
    position: relative;
}
