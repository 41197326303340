//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// Sorted by highest z-index to the lowest one
$zIndexScreenTooSmallOverlay:           13377000;
$zIndexHeader:                          13374500;
$zIndexLoadingOverlay:                  13373000;
$zIndexTooltip:                         13372800;
$zIndexModalOverlayPaneOpenCloseButton: 13372511;
$zIndexModalOverlayPaneOpen:            13372510;
$zIndexModalOverlayPaneGradient:        13371000;
$zIndexModalWrapper:                    13372500;
$zIndexDotListMenu:                     13371420;
$zIndexDropDownMenu:                    13371400;
$zIndexDatePickerOpen:                  13371338;
$zIndexAbsoluteEditButton:              13371337;
$zIndexDatePicker:                      13371337;
$zIndexSliderHandle:                    13371320;
$zIndexSliderTrack:                     13371310;
$zIndexSliderRailInner:                 13371302;
$zIndexSliderRail:                      13371300;
$zIndexVerticalResizer:                 13371250;
$zIndexDefault:                         13371000;

:export
{
    zIndexLoadingOverlay:       $zIndexLoadingOverlay;
    zIndexTooltip:              $zIndexTooltip;
    zIndexModalOverlayPaneOpen: $zIndexModalOverlayPaneOpen;
    zIndexModalWrapper:         $zIndexModalWrapper;
    zIndexHeader:               $zIndexHeader;
    zIndexDropDownMenu:         $zIndexDropDownMenu;
    zIndexDatePickerOpen:       $zIndexDatePickerOpen;
    zIndexAbsoluteEditButton:   $zIndexAbsoluteEditButton;
    zIndexDatePicker:           $zIndexDatePicker;
    zIndexSliderHandle:         $zIndexSliderHandle;
    zIndexSliderTrack:          $zIndexSliderTrack;
    zIndexSliderRailInner:      $zIndexSliderRailInner;
    zIndexSliderRail:           $zIndexSliderRail;
    zIndexVerticalResizer:      $zIndexVerticalResizer;
    zIndexDefault:              $zIndexDefault;
}
