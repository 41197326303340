//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

.item
{
    height:        49px;
    border-radius: 10px;
    background:    $colorGrayLight2;
    color:         $colorGrayLighter5;
    margin:        0 0 10px 0;
    display:       flex;
    align-items:   center;
    padding:       0 20px;

    &.itemFullWidth
    {
        width:        100%;
        margin-right: 0;
    }

    &.itemSmall
    {
        margin-right: 10px;
    }
}

.itemWrapper
{
    display:   flex;
    flex-wrap: wrap;
}

.wrapper
{
    background:      $colorGrayDark;
    padding:         30px;
    display:         flex;
    justify-content: center;
    align-items:     center;
    position:        relative;

    .wrapperInner
    {
        width:     $profileContentWidth;
        color:     $colorWhite;
        max-width: 100%;
    }
}