//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

.wrapper
{
    background:              $colorGrayDark;
    padding:                 30px;
    display:                 flex;
    justify-content:         center;
    align-items:             center;
    position:                relative;
    border-top-left-radius:  10px;
    border-top-right-radius: 10px;

    .wrapperInner
    {
        width:     $profileContentWidth;
        color:     $colorWhite;
        max-width: 100%;
    }
}