//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/fontWeights';
@import '../../../styles/variables';

.wrapper
{
    display:     flex;
    flex-flow:   column nowrap;
    align-items: center;

    .logo
    {
        $size:         150px;

        margin-top:    15px;
        overflow:      hidden;
        display:       block;
        object-fit:    cover;
        cursor:        pointer;
        box-sizing:    border-box;
        transition:    border .1s linear;
        font-size:     52px;
        width:         $size;
        height:        $size;
        border-radius: $size;
        margin-bottom: 35px;

        &.fallback
        {
            display:          flex;
            align-items:      center;
            justify-content:  center;
            color:            $colorGrayLight;
            user-select:      none;
            background-color: $colorGrayLighter3;
            font-family:      $fontSourceGeneralBody;
            font-weight:      $fontWeightBlack;
        }

        &.bannerImage
        {
            width:         670px;
            height:        168px;
            border-radius: 0;
        }
    }

    .buttonContainer
    {
        display:   flex;
        flex-flow: row wrap;

        > div
        {
            margin: 0 11px;
        }
    }

    .fileUpload
    {
        display: none;
    }
}

.information
{
    align-self:  flex-start;
    color:       $colorGrayLighter9;
    font-weight: $fontWeightRegular;
    font-size:   15px;
    white-space: pre-wrap;
}
