//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

.wrapper
{
    background:  $colorGrayLight2;
    transition:  background-color $defaultEffectAnimationSpeed linear;
    color:       $colorWhite;
    cursor:      pointer;
    user-select: none;

    &.wrapperThemeBig
    {
        font-size:   24px;
        font-weight: 700;
        padding:     10px 25px;

        strong
        {
            font-weight: normal;
            color:       $colorGrayLighter3;
            font-size:   14px;
            padding:     2px 0 0 0;
        }
    }

    &.wrapperThemeSmall
    {
        padding:     5px 10px;
        font-size:   15px;
        font-weight: normal;
    }

    &:hover
    {
        background: $colorGrayLighter2;
    }

    strong
    {
        display: block;
    }
}
