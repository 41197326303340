//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/fontWeights';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

.wrapper
{
    position: relative;
    z-index:  $zIndexDatePicker;
    margin:   0 0 20px 0;

    &.wrapperNoMarginBottom
    {
        margin-bottom: 0;
    }

    &.wrapperOpen
    {
        z-index: $zIndexDatePickerOpen;
    }
}

$datePickerBackgroundColor: $colorGrayLighter2;
$datePickerBorderRadius:    5px;

:global
{
    .react-datepicker-popper[data-placement^="bottom"]
    {
        .react-datepicker__triangle
        {
            left: 50px !important;

            &,
            &::before
            {
                border-bottom-color: $datePickerBackgroundColor !important;
            }
        }
    }

    .react-datepicker-popper[data-placement^="top"]
    {
        .react-datepicker__triangle
        {
            right: 50px !important;

            &,
            &::before
            {
                border-top-color: $datePickerBackgroundColor !important;
            }
        }
    }

    .react-datepicker-wrapper
    {
        display: flex !important;
        flex:    1;
    }

    .react-datepicker
    {
        border-color:     $datePickerBackgroundColor !important;
        border-radius:    $datePickerBorderRadius !important;
        background-color: $colorGray !important;
        font-family:      $fontSourceGeneralBody !important;
    }

    .react-datepicker__header
    {
        background-color: $datePickerBackgroundColor !important;
        border-radius:    0 !important;
        border-bottom:    none !important;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name
    {
        &,
        &.react-datepicker__day-name,
        &.react-datepicker__day-name:hover,
        &.react-datepicker__day-name:active,
        &.react-datepicker__day--disabled,
        &.react-datepicker__month-text--disabled,
        &.react-datepicker__quarter-text--disabled,
        &.react-datepicker__year-text--disabled,
        &.react-datepicker__day--disabled:hover,
        &.react-datepicker__day--disabled:active,
        &.react-datepicker__month-text--disabled:hover,
        &.react-datepicker__month-text--disabled:active,
        &.react-datepicker__quarter-text--disabled:hover,
        &.react-datepicker__quarter-text--disabled:active,
        &.react-datepicker__year-text--disabled:hover,
        &.react-datepicker__year-text--disabled:active
        {
            color:            $colorGrayLighter9 !important;
            border:           1px solid transparent !important;
            background-color: transparent !important;
            border-radius:    4px !important;
            outline:          none !important;
            transition:       background-color $defaultEffectAnimationSpeed linear,
                              border-color $defaultEffectAnimationSpeed linear,
                              color $defaultEffectAnimationSpeed linear;
        }

        &:hover,
        &:focus
        {
            background:   $colorGray !important;
            border-color: $colorTurquoise !important;
        }

        &.react-datepicker__day--selected,
        &:active
        {
            background:  $colorTurquoise !important;
            color:       $colorGrayDark !important;
            font-weight: $fontWeightRegular;
        }

        &.react-datepicker__month-text--keyboard-selected,
        &.react-datepicker__quarter-text--keyboard-selected,
        &.react-datepicker__year-text--keyboard-selected
        {
            font-weight: $fontWeightSemiBold;
        }

        &.react-datepicker__day--disabled,
        &.react-datepicker__month-text--disabled,
        &.react-datepicker__quarter-text--disabled,
        &.react-datepicker__year-text--disabled
        {
            &,
            &:hover,
            &:active
            {
                color:  $colorGrayLighter7 !important;
                cursor: not-allowed !important;
            }
        }
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header
    {
        color: $colorGrayLighter9 !important;
    }

    .react-datepicker__navigation--previous,
    .react-datepicker__navigation--next
    {
        transition: border-color $defaultEffectAnimationSpeed linear;
        outline:    none !important;
    }

    .react-datepicker__navigation--previous
    {
        border-right-color: $colorGrayLighter9 !important;

        &:focus,
        &:hover
        {
            border-right-color: $colorTurquoise !important;
        }
    }

    .react-datepicker__navigation--next
    {
        border-left-color: $colorGrayLighter9 !important;

        &:focus,
        &:hover
        {
            border-left-color: $colorTurquoise !important;
        }
    }
}