//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
@import '../../../styles/colors';
@import '../../../styles/variables';

.videoContainer
{
    position:    relative;
    padding-top: 56.25%;

    .videoFrame
    {
        position: absolute;
        top:      0;
        left:     0;
    }
}