//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

.shortFactList
{
    list-style: none;
    box-sizing: border-box;
    padding:    0;
    margin:     0;
    display:    flex;
    flex-wrap:  wrap;

    li
    {
        display:        flex;
        flex-direction: row;
        flex-grow:      1;
        flex-shrink:    0;
        padding-right:  10px;
        margin:         0 0 10px 0;
        box-sizing:     border-box;
        white-space:    nowrap;

        .shortFactIconWrapper
        {
            margin: 0 12px 0 0;
            width:  20px;

            svg
            {
                *
                {
                    stroke: $colorTurquoise;
                }
            }
        }

        .shortFactText
        {
            flex: 1;
        }
    }
}