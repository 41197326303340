//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

.wrapper
{
    padding:    $headerHeight 0 0 0;
    flex:       1;
    box-sizing: border-box;

    .wrapperInner
    {
        height:     100%;
        overflow:   auto;
        flex:       1;
        box-sizing: border-box;
    }
}