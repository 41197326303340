//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fontWeights';
@import '../../../styles/zIndexes';

.wrapper
{
    h3
    {
        font:          $fontWeightSemiBold;
        font-size:     15px;
        color:         $colorBlack;
        background:    $colorTurquoise;
        border-radius: 3px;
        font-weight:   normal;
        padding:       3px 8px;
        display:       inline-block;
        margin:        0 0 20px 0;
    }
}