//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../styles/fontWeights';

$fontsPath:             '@/assets/fonts/';

@font-face
{
    font-family:  'LFT Etica';
    src:          url('#{$fontsPath}LFTEtica-Italic.woff2') format('font-woff2'),
                  url('#{$fontsPath}LFTEtica-Italic.woff') format('font-woff'),
                  url('#{$fontsPath}LFTEtica-Italic.ttf') format('font-truetype');
    font-weight:  $fontWeightRegular;
    font-style:   italic;
    font-display: fallback;
}

@font-face
{
    font-family:  'LFT Etica';
    src:          url('#{$fontsPath}LFTEtica-Bold.woff2') format('font-woff2'),
                  url('#{$fontsPath}LFTEtica-Bold.woff') format('font-woff'),
                  url('#{$fontsPath}LFTEtica-Bold.ttf') format('font-truetype');
    font-weight:  $fontWeightBold;
    font-style:   normal;
    font-display: fallback;
}

@font-face
{
    font-family:  'LFT Etica';
    src:          url('#{$fontsPath}LFTEtica-BoldItalic.woff2') format('font-woff2'),
                  url('#{$fontsPath}LFTEtica-BoldItalic.woff') format('font-woff'),
                  url('#{$fontsPath}LFTEtica-BoldItalic.ttf') format('font-truetype');
    font-weight:  $fontWeightBold;
    font-style:   italic;
    font-display: fallback;
}

@font-face
{
    font-family:  'LFT Etica';
    src:          url('#{$fontsPath}LFTEtica-Regular.woff2') format('font-woff2'),
                  url('#{$fontsPath}LFTEtica-Regular.woff') format('font-woff'),
                  url('#{$fontsPath}LFTEtica-Regular.ttf') format('font-truetype');
    font-weight:  $fontWeightRegular;
    font-style:   normal;
    font-display: fallback;
}

$fontSourceGeneralBody: 'LFT Etica', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
                        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

:export
{
    fontSourceGeneralBody: $fontSourceGeneralBody;
}
