//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

.container
{
    height:          $headerHeight;
    position:        fixed;
    top:             0;
    left:            0;
    width:           100%;
    background:      $colorGrayLight2;
    border-bottom:   1px solid $colorGray;
    display:         flex;
    justify-content: space-between;
    z-index:         $zIndexHeader;

    .containerLeft,
    .containerRight
    {
        &.containerLeft
        {
            flex:        1;
            padding:     0 0 0 23px;
            display:     flex;
            align-items: center;

            a
            {
                width:        100%;
                margin-right: 20px;

                @media(min-width: $screen-sm)
                {
                    margin-right: 0;
                }

                h1
                {
                    text-indent:       -13371337px;
                    overflow:          hidden;
                    width:             100%;
                    height:            25px;
                    cursor:            pointer;
                    background-image:  url('../../../assets/images/framebutler-logo-header.svg');
                    background-size:   contain;
                    background-repeat: no-repeat;

                    @media(min-width: $screen-sm)
                    {
                        width:  215px;
                        height: 25px;
                    }
                }
            }
        }

        &.containerRight
        {
            flex:          0 1;
            display:       flex;
            justify-items: flex-end;

            @media(min-width: $screen-sm)
            {
                flex: 0;
            }
        }
    }
}
