//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/fontWeights';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

$overlayWidth: 750px;

.contentWrapper
{

}

.modalContainer
{
    cursor:        default;
    border-radius: 3px;
    width:         $overlayWidth;
    max-width:     100%;
    margin:        0 auto;
}

.modalHeader
{
    position: relative;

    h2
    {
        font-weight:     $fontWeightSemiBold;
        font-size:       20px;
        color:           $colorGrayLighter9;
        text-align:      center;
        border-bottom:   1px solid $colorGrayLighter2;
        padding:         0 0 18px 0;
        margin:          0 0 20px 0;
        height:          40px;
        display:         flex;
        justify-content: center;
        align-items:     center;
    }
}

.modalWrapper
{
    background:          $colorBlack90;
    z-index:             $zIndexModalWrapper;
    height:              100%;
    width:               100%;
    position:            fixed;
    top:                 70px;
    left:                0;
    display:             flex;
    justify-content:     center;
    align-items:         flex-start;
    cursor:              pointer;
    overscroll-behavior: contain;
}

.modalWrapperInner
{
    padding: 20px 0;

    @media (min-width: $screen-md) and (min-height: $screen-sm)
    {
        padding: 100px 0;
    }
}

.progressBarWrapper
{
    background: $colorGrayLighter2;
    height:     40px;
    width:      100%;
    position:   absolute;
    top:        70px;
    left:       0;
    display:    flex;
    cursor:     default;
    z-index:    $zIndexModalOverlayPaneOpen;

    .progressBarWrapperInner
    {
        width:   $overlayWidth;
        margin:  0 auto;
        display: flex;

        .progressBarWrapperInnerLeft
        {
            flex:            1;
            align-items:     center;
            justify-content: center;
            display:         flex;

            .progressBar
            {
                $progressBarHeight: 11px;

                flex:               1;
                height:             $progressBarHeight;
                border:             1px solid $colorGrayLighter8;
                border-radius:      $progressBarHeight;
                position:           relative;
                box-sizing:         border-box;

                .progressBarProgress
                {
                    position:      absolute;
                    top:           -1px;
                    left:          -1px;
                    height:        $progressBarHeight;
                    background:    $colorTurquoise;
                    border-radius: $progressBarHeight;
                    transition:    width $defaultEffectAnimationSpeed linear;
                }
            }
        }

        .progressBarWrapperInnerRight
        {
            width:           40px;
            display:         flex;
            align-items:     center;
            justify-content: flex-end;
            color:           $colorWhite;
            font-size:       15px;
            font-weight:     $fontWeightRegular;
        }
    }
}

.wrapper
{

}
