//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/fontWeights';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

$contentPaddingBottom:      70;
$overlayPaneAnimationSpeed: 0.3s;

.content
{
    box-sizing: border-box;
    padding:    0 0 ($contentPaddingBottom * 1px) 0;
    max-height: 100%;
    height:     100%;

    &.contentNoFooter
    {
        padding-bottom: 0;
    }

    .contentInner
    {
        padding:    20px 40px 40px 40px;
        box-sizing: border-box;
    }
}

.description
{
    color:       $colorGrayLighter9;
    font-size:   18px;
    font-weight: $fontWeightRegular;
    text-align:  center;
    line-height: 1.3;

    @media (min-width: $screen-md)
    {
        padding:   0 100px;
        font-size: 22px;
    }

    &.largeDescription
    {
        font-size:   20px;
        line-height: 1.16;
        font-weight: $fontWeightBold;

        @media (min-width: $screen-md)
        {
            font-size: 36px;
        }
    }
}

.footer
{
    position: absolute;
    bottom:   0;
    left:     0;
    width:    100%;
    height:   54px;
}

.header
{
    padding:  20px;
    position: relative;

    @media (min-width: $screen-md)
    {
        padding: 40px;
    }

    h3
    {
        font-size:   20px;
        font-weight: $fontWeightSemiBold;
        margin:      0;
        padding:     0;
        color:       $colorGrayLighter9;
        transition:  filter $overlayPaneAnimationSpeed linear;
    }
}

.headerGradient
{
    background-image:  url('../../../assets/images/gradient-1F2021-to-transparent.png');
    background-repeat: repeat-x;
    height:            20px;
    width:             100%;
    position:          absolute;
    bottom:            -20px;
    left:              0;
    z-index:           $zIndexModalOverlayPaneGradient;
}

.footerGradient
{
    background-image:  url('../../../assets/images/gradient-1F2021-to-transparent.png');
    background-repeat: repeat-x;
    height:            20px;
    width:             100%;
    position:          absolute;
    top:               -20px;
    left:              0;
    z-index:           $zIndexModalOverlayPaneGradient;
    transform:         rotate(180deg);

    &.noFooterGradient
    {
        background: none;
    }
}

.wrapper
{
    display:       flex;
    flex-flow:     column;
    visibility:    hidden;
    opacity:       0;
    box-sizing:    border-box;
    background:    $colorGrayLight2;
    border-radius: 10px;
    position:      relative;
    margin:        0 0 20px 0;
    max-width:     100vw;
    overflow:      hidden;
    transition:    margin-bottom $overlayPaneAnimationSpeed linear,
                   opacity $overlayPaneAnimationSpeed linear,
                   filter $overlayPaneAnimationSpeed linear,
                   height $overlayPaneAnimationSpeed linear;

    @media (max-width: $screen-md)
    {
        position:   fixed;
        top:        70px;
        left:       0;
        min-height: calc(100% - 70px);
        max-height: calc(100% - 70px);
        min-width:  100vw;
        overflow:   auto;
    }

    &.wrapperStateOpen
    {
        visibility: visible;
        opacity:    0;
    }

    &.wrapperInitialized
    {
        visibility: visible;
        opacity:    1;

        &.wrapperStateDone
        {
            margin-bottom: 0;
            height:        0;
            opacity:       0;

            .wrapperInner
            {
                height:         0;
                opacity:        0;
                pointer-events: none;
            }
        }

        &.wrapperStateOpen
        {
            // For weird reasons we have to increase the duration of this animation since
            // otherwise the overflow is set too early
            $overlayFixAnimationSpeed: $overlayPaneAnimationSpeed * 2.5;

            opacity:                   1;
            z-index:                   $zIndexModalOverlayPaneOpen;

            @media (min-width: $screen-md)
            {
                animation: allow-overflow $overlayFixAnimationSpeed linear 0s forwards;
            }

            .wrapperInner
            {
                flex:           1;
                pointer-events: all;

                @media (min-width: $screen-md)
                {
                    animation: allow-overflow $overlayFixAnimationSpeed linear 0s forwards;
                }
            }
        }

        &.wrapperStateUpcoming
        {
            .wrapperInner
            {
                height:         0;
                opacity:        0;
                pointer-events: none;
            }
        }
    }

    .wrapperInner
    {
        box-sizing: border-box;
        overflow:   hidden;
        transition: opacity $overlayPaneAnimationSpeed linear,
                    height $overlayPaneAnimationSpeed linear;

        @media (max-width: $screen-md)
        {
            overflow: scroll;
        }
    }
}

@keyframes allow-overflow
{
    0%
    {
        overflow: hidden;
    }
    100%
    {
        overflow: visible;
    }
}

:export
{
    contentPaddingBottom: $contentPaddingBottom;
}
