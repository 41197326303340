//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

.headerButton
{
    margin-right: 10px;

    @media(min-width: $screen-sm)
    {
        flex:         1;
        margin-right: 37px;
    }

    a
    {
        position:        relative;
        text-decoration: none;
        font-size:       15px;
        color:           $colorWhite;
        height:          100%;
        flex:            1;
        line-height:     67px;
        transition:      color $defaultEffectAnimationSpeed linear;

        &::before
        {
            position:         absolute;
            content:          '';
            width:            100%;
            height:           3px;
            bottom:           -5px;
            background-color: transparent;
            transition:       background-color $defaultEffectAnimationSpeed linear;
        }

        &:hover
        {
            color: $colorTurquoise;
        }

        svg
        {
            margin: 0 5px 0 0;
        }
    }

    &.headerButtonActive
    {
        a
        {
            &::before
            {
                background-color: $colorTurquoise;
            }
        }
    }
}
