//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fontWeights';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

$borderWidth: 1px;

.content
{
    overflow:   hidden;
    transition: height $defaultEffectAnimationSpeed linear,
                opacity $defaultEffectAnimationSpeed linear;

    &.contentClosed
    {
        height:  0;
        opacity: 0;
    }

    &.contentOpened
    {
        opacity: 1;
    }

    .contentInner
    {
        border-top: 1px solid $colorGrayLighter7;
        margin-top: 15px;
    }
}

.header
{
    display:         flex;
    justify-content: space-between;
    flex-wrap:       wrap;

    .headerCenter
    {
        display:         flex;
        align-items:     center;
        justify-content: space-between;
        margin-left:     auto;
        margin-right:    auto;
        padding-bottom:  (20px - $borderWidth);

        > *
        {
            // This is a workaround for the lack of the support of
            // https://developer.mozilla.org/en-US/docs/Web/CSS/gap#Flex_layout
            margin-right: 18px;

            &:last-child
            {
                margin-right: 0;
            }
        }
    }

    .headerLeft
    {
        display:        flex;
        align-items:    center;
        padding-bottom: (20px - $borderWidth);

        .avatarWrapper
        {
            margin: 4px 20px 0 0;
        }

        .textWrapper
        {
            color:     $colorWhite;
            max-width: 334px;

            h3
            {
                font-size:   18px;
                font-weight: $fontWeightSemiBold;
                margin:      0;
                padding:     0;
            }

            strong
            {
                color:       $colorGrayLighter6;
                font-size:   14px;
                font-weight: $fontWeightRegular;

                &.textUndefined
                {
                    color: $colorRedLight;
                }
            }
        }
    }

    .headerRight
    {
        display:         flex;
        align-items:     center;
        justify-content: space-between;
        margin-left:     auto;
        flex-wrap:       wrap;

        > *
        {
            // This is a workaround for the lack of the support of
            // https://developer.mozilla.org/en-US/docs/Web/CSS/gap#Flex_layout
            margin-right:  18px;
            margin-bottom: (20px - $borderWidth);

            &:last-child
            {
                margin-left: 0;
            }
        }
    }

    .headerToggle
    {
        flex-shrink:   1;
        width:         70px;
        margin-bottom: 20px;
        display:       flex;
        align-items:   center;

        svg
        {
            $size:      40px;

            cursor:     pointer;
            width:      $size;
            height:     $size;
            transition: transform $defaultEffectAnimationSpeed linear;

            *
            {
                stroke: $colorGrayLighter6;
            }

            &:hover
            {
                *
                {
                    stroke: $colorWhite;
                }
            }
        }

        &.headerToggleFlipped
        {
            svg
            {
                transform: rotate(180deg);
            }
        }
    }
}

.wrapper
{
    transition:    border-color $defaultEffectAnimationSpeed linear;
    background:    $colorGrayLight2;
    border-radius: 10px;
    border:        1px solid $colorGrayLight2;
    padding:       (20px - $borderWidth) (25px - $borderWidth) 0 (20px - $borderWidth);
    margin:        0 0 20px 0;

    &.wrapperHighlighted
    {
        border-color: $colorTurquoise;
    }
}