//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fontWeights';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

.smallDownloadButton
{
    $size:           35px;

    width:           $size;
    height:          $size;
    border-radius:   $size;
    border:          1px solid $colorTurquoise;
    display:         flex;
    justify-content: center;
    margin:          0 0 0 8px;
    align-items:     center;
    cursor:          pointer;
    transition:      background-color $defaultEffectAnimationSpeed linear;

    &:hover
    {
        background: $colorTurquoise;

        svg
        {
            width:      20px;
            height:     20px;
            margin-top: -2px;

            *
            {
                stroke: $colorBlack;
            }
        }
    }

    svg
    {
        width:      20px;
        height:     20px;
        margin-top: -2px;

        *
        {
            stroke: $colorTurquoise;
        }
    }
}