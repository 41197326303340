//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/fontWeights';
@import '../../../styles/zIndexes';

.link
{
    text-decoration: none;
}

.avatar
{
    overflow:      hidden;
    display:       block;
    object-fit:    cover;
    cursor:        pointer;
    box-sizing:    border-box;
    border-radius: 70px;
    transition:    border .1s linear;

    &:hover
    {
        border: 3px solid $colorTurquoise;
    }

    &.extraLarge
    {
        $size:         150px;

        font-size:     52px;
        width:         $size;
        height:        $size;
        border-radius: $size;
    }

    &.large
    {
        $size:         70px;

        font-size:     32px;
        width:         $size;
        height:        $size;
        border-radius: $size;
    }

    &.default
    {
        $size:         50px;

        font-size:     26px;
        width:         $size;
        height:        $size;
        border-radius: $size;
    }

    &.small
    {
        $size:         40px;

        font-size:     22px;
        width:         $size;
        height:        $size;
        border-radius: $size;
    }

    &.fallback
    {
        display:          flex;
        align-items:      center;
        justify-content:  center;
        color:            $colorGrayLight;
        user-select:      none;
        background-color: $colorGrayLighter3;
        font-family:      $fontSourceGeneralBody;
        font-weight:      $fontWeightBlack;
    }

    .noHover
    {
        &:hover
        {
            border: 0;
        }
    }
}