//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fontWeights';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

.subText
{
    color:       $colorGrayLighter7;
    font-size:   16px;
    line-height: 20px;

    a
    {
        color:      $colorGrayLighter7;
        transition: color $defaultEffectAnimationSpeed linear;

        &:hover
        {
            color: $colorTurquoise;
        }
    }
}

.wrapper
{
    margin: auto 50px;

    h3
    {
        font-size:   36px;
        color:       $colorWhite;
        margin:      0 0 20px 0;
        font-weight: $fontWeightSemiBold;
    }
}

.buttonContainer
{
    margin-top:   30px;
    margin-right: auto;
    display:      flex;
    flex-flow:    row nowrap;

    > div:not(:first-child)
    {
        margin-left: 10px;
    }
}
