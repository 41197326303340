//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fontWeights';
@import '../../../styles/breakpoints';
@import '../../../styles/zIndexes';

.labelWrapper
{
    box-sizing:      border-box;
    width:           200px;
    padding:         0 20px 0 0;
    color:           $colorGrayLighter9;
    font-weight:     $fontWeightSemiBold;
    font-size:       15px;
    display:         flex;
    flex-direction:  column;
    justify-content: flex-start;
    max-height:      50px;

    @media (min-width: $screen-md)
    {
        width: 280px;
    }

    span
    {
        color: $colorGrayLighter3;
    }
}

.textWrapper
{
    flex:        1;
    color:       $colorGrayLighter9;
    font-weight: $fontWeightRegular;
    font-size:   15px;

    &.textUndefined
    {
        color: $colorRedLight;
    }
}

.wrapper
{
    display: flex;
    margin:  0 0 20px 0;
}