//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/fontWeights';
@import '../../../styles/zIndexes';

.wrapper
{
    margin: auto 50px;

    h3
    {
        font-size:   36px;
        color:       $colorWhite;
        margin:      0 0 20px 0;
        font-weight: $fontWeightSemiBold;
    }
}
