//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/breakpoints';
@import '../../../styles/zIndexes';

.buttonWrapper
{
    display:         flex;
    flex-flow:       row wrap;
    justify-content: flex-end;

    > a,
    > div
    {
        margin-top:  20px;
        margin-left: 20px;

        &:first-child
        {
            margin-left: 0;
        }
    }
}

.textInputWrapper
{
    background: $colorGrayLighter7;

    .fileListWrapper
    {
        border-top: 1px solid $colorGrayLighter3;
        padding:    15px 20px;
        display:    flex;
        flex-wrap:  wrap;

        > div
        {
            margin-right:  15px;
            margin-bottom: 15px;

            &:last-child
            {
                margin-right: 0;
            }
        }
    }
}

.wrapper
{
    background:    $colorGrayLight2;
    border-radius: 10px;
    margin:        0 0 17px 73px;
}