//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fontWeights';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

.container
{
    display:        flex;
    flex-direction: column;
    margin:         auto;
    align-items:    center;

    .containerAlertBox
    {
        width:     500px;
        max-width: 90%;
    }

    .buttonWrapper
    {
        padding: 50px 0 0 0;
    }

    .textWrapper
    {
        margin-top:  20px;
        text-align:  center;
        color:       $colorWhite;
        max-width:   500px;
        line-height: 1.5;

        h3
        {
            color:         $colorTurquoise;
            font-size:     40px;
            margin-bottom: 20px;
        }
    }
}
