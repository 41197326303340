//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fontWeights';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

.choice
{
    color:       $colorGrayLighter3;
    font-size:   16px;
    font-weight: $fontWeightRegular;
    display:     flex;
    box-sizing:  border-box;
    margin:      0 4px 14px 4px;
    user-select: none;

    &.choiceWidthHalf
    {
        flex: 1 1 calc(50% - 8px);
    }

    &.choiceWidthFull
    {
        padding-left:  0;
        padding-right: 0;
        flex:          1 0 calc(100% - 8px);
    }

    &.choiceSelected
    {
        .choiceCheckMark,
        .choiceCheckMarkInner1,
        .choiceCheckMarkInner2,
        {
            border-color: $colorTurquoise !important;
        }
    }

    .choiceInner
    {
        background:    $colorGrayLighter2;
        padding:       5px 16px;
        display:       flex;
        flex:          1;
        align-items:   center;
        min-height:    50px;
        box-sizing:    border-box;
        cursor:        pointer;
        border-radius: 3px;

        &:hover
        {
            .choiceCheckMark
            {
                border-color: $colorTurquoise !important;
            }
        }

        &.choiceInnerError
        {
            border: 1px solid $colorRed;
        }

        .choiceCheckMark
        {
            $size:         24px;

            width:         $size;
            height:        $size;
            min-width:     $size;
            border-radius: $size;
            border:        1px solid $colorGrayLighter3;
            margin:        0 10px 0 0;
            transition:    border-color $defaultEffectAnimationSpeed linear;
            box-sizing:    border-box;

            .choiceCheckMarkInner1
            {
                width:         100%;
                height:        100%;
                border-radius: 100%;
                box-sizing:    border-box;
                border:        1px solid $colorGrayLighter2;
                padding:       4px;
                transition:    border-color $defaultEffectAnimationSpeed linear;

                .choiceCheckMarkInner2
                {
                    width:         100%;
                    height:        100%;
                    border-radius: 100%;
                    box-sizing:    border-box;
                    border:        2px solid $colorGrayLighter2;
                    transition:    border-color $defaultEffectAnimationSpeed linear;
                }
            }
        }
    }
}

.wrapper
{
    display:   flex;
    flex-flow: row wrap;
    margin:    0 -4px;

    &.alignSelect
    {
        padding-right: 50px;
    }
}
