//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/zIndexes';

.alertBox
{
    padding:       15px 40px 15px 54px;
    border-radius: 6px;
    font-size:     15px;
    margin:        0 0 20px 0;
    position:      relative;
    background:    $colorTurquoise;
    color:         $colorGrayLight;

    svg
    {
        *
        {
            stroke: $colorGrayLight !important;
        }
    }

    .alertBoxIcon
    {
        background: $colorTurquoise;

        svg
        {

            *
            {
                stroke: $colorGrayLight !important;
            }
        }
    }
}

.alertBoxCloseButtonWrapper
{
    position: absolute;
    top:      13px;
    right:    10px;
    cursor:   pointer;
}

.alertBoxIcon
{
    $size:           24px;

    width:           $size;
    height:          $size;
    border-radius:   $size / 2;
    position:        absolute;
    top:             28px;
    left:            18px;
    margin:          -($size / 2) 0 0 0;
    display:         flex;
    justify-content: center;
    align-items:     center;
}