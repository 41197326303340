//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/colors';
@import '../../styles/zIndexes';

.container
{
    display:        flex;
    flex-direction: column;
    margin:         auto;
    align-items:    center;

    .buttonWrapper
    {
        display:   flex;
        flex-flow: row wrap;
        padding:   50px 0 0 0;

        a
        {
            margin: 0 10px;
        }
    }

    .textWrapper
    {
        margin-top: 20px;
        text-align: center;
        color:      $colorWhite;

        h3
        {
            color:         $colorTurquoise;
            font-size:     40px;
            margin-bottom: 20px;
        }

        strong
        {
            padding: 0 50px;
            display: block;
        }
    }
}
