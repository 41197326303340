//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

.bottomWrapper
{
    background:      $colorGrayDark;
    padding:         30px;
    display:         flex;
    justify-content: center;
    align-items:     center;
    position:        relative;

    .bottomWrapperInner
    {
        width:      $profileContentWidth;
        color:      $colorWhite;
        max-width:  100%;
        text-align: center;

        h3
        {
            font-weight: normal;
            font-size:   22px;
        }
    }
}

.linkList
{
    list-style:      none;
    display:         flex;
    flex-flow:       row wrap;
    margin:          0 -10px;
    align-items:     center;
    justify-content: center;

    .link
    {
        margin: 20px 10px 0 10px;

        a
        {
            $size:           50px;

            display:         flex;
            justify-content: center;
            align-items:     center;
            width:           $size;
            height:          $size;
            background:      $colorWhite;
            transition:      background-color $defaultEffectAnimationSpeed linear;

            svg
            {
                *
                {
                    stroke: $colorBlack;
                }
            }

            &:hover
            {
                background: $colorTurquoise;

                svg
                {
                    *
                    {
                        stroke: $colorWhite;
                    }
                }
            }
        }
    }
}

.topWrapper
{
    height:                  240px;
    background-position:     center;
    background-size:         cover;
    border-top-left-radius:  10px;
    border-top-right-radius: 10px;
    display:                 flex;
    justify-content:         center;
    align-items:             center;
}

.wrapper
{

}
