//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fontWeights';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

$contentPaddingBottom:      46;
$overlayPaneAnimationSpeed: 0.3s;

.content
{
    box-sizing:      border-box;
    max-height:      100%;
    height:          100%;
    padding:         15px;
    display:         flex;
    justify-content: space-between;
    align-items:     center;
}

.tip
{
    display: flex;

    .tipIconWrapper
    {
        margin: 0 7px 0 0;
        width:  20px;

        svg
        {
            *
            {
                stroke: $colorGrayLighter7;
            }
        }
    }

    p
    {
        color:     $colorGrayLighter7;
        font-size: 16px;
        max-width: 370px;
    }
}

.wrapper
{
    box-sizing:    border-box;
    background:    $colorGrayLight2;
    border-radius: 10px;
    position:      relative;
    margin:        0 0 20px 0;

    .wrapperInner
    {
        box-sizing: border-box;
        overflow:   hidden;
        transition: opacity $overlayPaneAnimationSpeed linear,
                    height $overlayPaneAnimationSpeed linear;
    }
}

@keyframes allow-overflow
{
    0%
    {
        overflow: hidden;
    }
    100%
    {
        overflow: visible;
    }
}

:export
{
    contentPaddingBottom: $contentPaddingBottom;
}