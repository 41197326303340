//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/colors';
@import '../../styles/fonts';
@import '../../styles/zIndexes';

.container
{
    position:         fixed;
    top:              0;
    left:             0;
    width:            100vw;
    height:           100vh;
    padding:          20px;
    display:          flex;
    box-sizing:       border-box;
    align-items:      center;
    justify-content:  center;
    background-color: $colorGrayLight2;

    .textWrapper
    {
        font-family: $fontSourceGeneralBody;
        color:       $colorWhite;
        max-width:   500px;

        h1
        {
            font-size:     36px;
            line-height:   40px;
            margin-bottom: 17px;
        }

        p
        {
            font-size:   18px;
            line-height: 26px;
            white-space: pre-wrap;
        }

        .socialMediaContainer
        {
            display:     flex;
            flex-flow:   row nowrap;
            align-items: center;
            margin-top:  20px;

            a
            {
                display:     inline;
                margin-left: 10px;

                &:first-child
                {
                    margin-left: 15px;
                }

                > span
                {
                    display: inline;

                    svg
                    {
                        *
                        {
                            transition: stroke 0.1s linear;
                        }
                    }
                }
            }

            a
            {
                > span
                {
                    &:hover
                    {
                        svg
                        {
                            *
                            {
                                stroke: $colorTurquoise;
                            }
                        }
                    }
                }
            }
        }
    }
}
