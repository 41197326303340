//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/zIndexes';

*
{
    margin:  0;
    padding: 0;
}

body
{
    margin:                  0;
    padding:                 0;
    font-family:             $fontSourceGeneralBody;
    -webkit-font-smoothing:  antialiased;
    -moz-osx-font-smoothing: grayscale;
    background:              $colorGray;
}

.helpText
{
    color:       $colorWhite;
    font-weight: $fontWeightMedium;
    font-size:   15px;
    max-width:   300px;
    margin-left: auto;
}

:global a
{
    color: $colorTurquoise;
}

:global .Resizer.vertical
{
    position: relative;

    // This will increase the clickable area of the
    // vertical resizer
    &:before
    {
        content:  '';
        position: absolute;
        width:    5px;
        height:   100%;
        top:      0;
        left:     -2px;
        z-index:  $zIndexVerticalResizer;
    }
}
