//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fontWeights';
@import '../../../styles/zIndexes';

.avatarWrapper
{
    margin: 0 17px 0 0;
}

.textWrapper
{
    color: $colorWhite;

    h3
    {
        font-size:   24px;
        font-weight: $fontWeightSemiBold;
        margin:      0 0 1px 0;
        padding:     0;
    }

    strong
    {
        font-size:   14px;
        color:       $colorGrayLighter3;
        font-weight: 400;
    }
}

.wrapper
{
    display:     flex;
    align-items: center;
    padding:     12px 19px;
}