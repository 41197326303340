//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fontWeights';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

.avatarWrapper
{
    margin: 0 15px 0 0;
}

.link
{
    display:         flex;
    cursor:          pointer;
    flex-direction:  row;
    text-decoration: none;
    padding:         0 0 0 16px;
    color:           $colorWhite;
    height:          56px;
    border-right:    4px solid $colorGrayLight2;
    border-radius:   3px;
    overflow:        hidden;
    transition:      background-color $defaultEffectAnimationSpeed linear,
                     border-color $defaultEffectAnimationSpeed linear,
                     color $defaultEffectAnimationSpeed linear;
    align-items:     center;

    &:hover
    {
        background-color: $colorGrayLighter4;
        border-color:     $colorGrayLighter4;
    }

    &:active,
    &.linkActive
    {
        background-color: $colorGrayLighter2;
        border-color:     $colorTurquoise;
    }
}

.newContentIndicator
{
    $size:         16px;

    width:         $size;
    height:        $size;
    border-radius: $size;
    position:      absolute;
    top:           50%;
    right:         16px;
    margin-top:    $size / -2;
    background:    $colorTurquoise;
}

.subText
{
    font-size: 15px;
}

.text
{
    margin:      0;
    padding:     0;
    font-size:   18px;
    font-weight: $fontWeightSemiBold;
}

.textWrapper
{

}

.wrapper
{
    margin-bottom: 20px;
    position:      relative;

    &:last-child
    {
        margin-bottom: 0;
    }
}