//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

.icon
{
    background-size: contain;
    width:           100%;
    display:         flex;
    justify-content: center;
    align-items:     center;
    height:          auto;
    position:        relative;

    &.iconError
    {
        svg
        {
            *
            {
                fill:   $colorRed !important;
                stroke: none !important;
            }
        }
    }

    svg
    {
        *
        {
            transition: stroke $defaultEffectAnimationSpeed linear;
            stroke:     $colorWhite;
        }
    }
}
