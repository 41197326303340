//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/zIndexes';
@import '../../../styles/breakpoints';

.imageWrapper
{
    width:         150px;
    height:        150px;
    border-radius: 150px;
    overflow:      hidden;
    margin-bottom: 40px;

    @media (min-width: $screen-md)
    {
        width:         250px;
        height:        250px;
        border-radius: 250px;
    }

    img
    {
        width:      100%;
        height:     100%;
        object-fit: cover;
    }
}

.wrapper
{
    display:         flex;
    align-items:     center;
    justify-content: center;
}
