//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fontWeights';
@import '../../../styles/breakpoints';
@import '../../../styles/zIndexes';

.left
{
    flex:         1;
    margin-right: 20px;

    ul
    {
        list-style: none;
        margin:     0 0 20px 0;

        li
        {
            margin: 0 0 30px 0;

            &:last-child
            {
                margin-bottom: 0;
            }
        }
    }
}

.right
{
    max-width: 250px;
}

.wrapper
{
    display:   flex;
    flex-flow: row wrap;
    padding:   18px 0 0 0;
}