//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/zIndexes';

.avatarWrapper
{
    margin: 0 11px 0 0;
}

.iconWrapper
{

}

.userMenuWrapper
{
    transition:     opacity 0.25s ease, padding-top 0.25s ease;
    position:       absolute;
    top:            0;
    margin:         0;
    padding:        0 25px 0 0;
    opacity:        0;
    right:          0;
    user-select:    none;
    pointer-events: none;
    height:         500px;
    width:          420px;
}

.wrapper
{
    display:        none;
    flex-direction: row;
    padding:        0 37px 0 0;
    align-items:    center;

    @media (min-width: $screen-md)
    {
        display: flex;
    }

    &:hover
    {
        .userMenuWrapper
        {
            display:        block;
            padding-top:    74px;
            pointer-events: all;
            opacity:        1;
        }
    }
}
