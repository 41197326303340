//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/zIndexes';

.mainContent
{
    flex: 1;
}

.sidebar
{
    background: $colorGrayLight2;
    width:      270px;
    height:     100%;
}

.sidebarInner
{
    height:     100%;
    padding:    24px 10px;
    box-sizing: border-box;
}

.wrapper
{
    display: flex;
    height:  100%;
}