//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

.wrapper
{
    padding:        0;
    flex:           1;
    display:        flex;
    flex-direction: column;
    box-sizing:     border-box;

    .top,
    .center,
    .bottom
    {
        &.top
        {

        }

        &.center
        {
            flex-grow:     1;
            border-top:    1px solid $colorGrayDark;
            border-bottom: 1px solid $colorGrayDark;
            overflow:      hidden;
            // Without this the whole div is not visible in safari
            // @see https://lulububu.atlassian.net/browse/FRAMEBUTLERAPP-483
            height:        100%;
        }

        &.bottom
        {
            padding:     20px 0;
            margin-left: 20px;
        }

        .link
        {
            display:         inline-block;
            text-align:      center;
            font-size:       10px;
            color:           $colorGrayLighter2;
            transition:      color $defaultEffectAnimationSpeed linear;
            text-decoration: none;
            margin-right:    20px;
            margin-bottom:   10px;

            &:hover
            {
                color: $colorTurquoise;
            }
        }
    }
}