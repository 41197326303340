//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fontWeights';
@import '../../../styles/zIndexes';

.tooltip
{
    cursor: help;
}

:global .tooltipWrapper
{
    padding:         0;
    display:         flex;
    justify-content: center;
    align-items:     center;

    svg
    {
        height: 19px;
    }
}

:global .tooltipWrapperWhite
{
    svg
    {
        *
        {
            stroke: $colorWhite;
        }
    }

    &:hover
    {
        svg
        {
            *
            {
                stroke: $colorTurquoise;
            }
        }
    }
}

$tooltipArrowSize:      8px;
$tooltipBorderRadius:   5px;
$tooltipContentPadding: 10px;

:global .tooltipContentWrapper
{
    border-radius: 4px;
    margin:        0;
    line-height:   18px;

    h5,
    p
    {
        overflow:  hidden;
        color:     $colorWhite !important;
        font-size: 12px;
        padding:   0;
    }

    h5
    {
        margin:      0 0 4px 0;
        font-weight: $fontWeightSemiBold;
    }

    p
    {
        font-weight: $fontWeightRegular;
    }
}

:global .tooltipStyle
{
    padding:          $tooltipContentPadding !important;
    opacity:          1 !important;
    background-color: $colorBlack !important;
    max-width:        240px;
    z-index:          $zIndexTooltip !important;
}

:global .__react_component_tooltip.place-left::after
{
    border-left: $tooltipArrowSize solid $colorBlack !important;
}

:global .__react_component_tooltip.place-right::after
{
    border-right: $tooltipArrowSize solid $colorBlack !important;
}

:global .__react_component_tooltip.place-top::after
{
    border-top: $tooltipArrowSize solid $colorBlack !important;
}

:global .__react_component_tooltip.bottom-right::after
{
    right:  10px !important;
    margin: 0 !important;
    bottom: -8px !important;
    left:   auto !important;
}

:global .__react_component_tooltip.place-bottom::after
{
    border-bottom: $tooltipArrowSize solid $colorBlack !important;
}