//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

.clearIndicator
{
    margin-right: 10px;

    svg
    {
        width: 20px;

        *
        {
            stroke: $colorGrayLighter3 !important;
        }
    }

    &:hover
    {
        svg
        {
            *
            {
                stroke: $colorTurquoise !important;
            }
        }
    }
}

.dropdownIndicator
{
    transition: transform $defaultEffectAnimationSpeed linear;

    &.dropdownIndicatorOpened
    {
        transform: rotate(180deg);
    }

    svg
    {
        *
        {
            stroke: $colorGrayLighter3 !important;
        }
    }
}

.noOptionsMessage
{
    padding:        50px;
    color:          $colorGrayLighter3;
    text-align:     center;
    flex-direction: column;
    display:        flex;
    align-items:    center;

    svg
    {
        width:      50px;
        margin:     0 0 20px 0;
        transition: transform $defaultEffectAnimationSpeed linear;

        &:hover
        {
            transform: rotate(-180deg) scale(1.2);

            *
            {
                stroke: $colorTurquoise !important;
            }
        }

        *
        {
            stroke: $colorGrayLighter3 !important;
        }
    }
}

.wrapper
{

}
