//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fontWeights';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

.avatarWrapper
{
    margin: 4px 14px 0 0;
}

.dotListWrapper
{
    position:       absolute;
    top:            12px;
    right:          11px;
    opacity:        0;
    pointer-events: none;
    transition:     transition $defaultEffectAnimationSpeed linear;
}

.header
{
    flex-direction: row;
    display:        flex;
    position:       relative;
    padding-right:  30px;
    cursor:         pointer;
    margin:         0 0 4px 0;
}

.isIncompleteBadge
{
    position:      absolute;
    top:           -10px;
    right:         10px;
    font-size:     10px;
    font-weight:   $fontWeightSemiBold;
    height:        20px;
    border-radius: 3px;
    line-height:   20px;
    white-space:   nowrap;
    padding:       0 5px;
    color:         $colorGrayDark;
    background:    $colorTurquoise;
}

.openCloseIndicatorWrapper
{
    position: absolute;
    top:      50%;
    right:    0;

    svg
    {
        transition: transform $defaultEffectAnimationSpeed linear;
    }
}

.openCloseIndicatorWrapperFlipped
{
    svg
    {
        transform: rotate(180deg);
    }
}

.menu
{
    height:         0;
    opacity:        0;
    pointer-events: none;
    transition:     height $defaultEffectAnimationSpeed linear,
                    opacity $defaultEffectAnimationSpeed linear;
    overflow:       hidden;

    &.menuOpened
    {
        opacity:        1;
        height:         270px;
        pointer-events: all;

        &.menuVideoProduction
        {
            height: 210px;
        }
    }

    &.menuIncomplete
    {
        filter: blur(2px);
    }

    .menuInner
    {
        border-top:  1px solid $colorGrayLighter4;
        padding-top: 20px;
    }
}

.shortFactsTextWrapper
{
    &.shortFactsTextWrapperIncomplete
    {
        filter: blur(2px);
    }
}

.textWrapper
{
    flex: 1;

    h2
    {
        color:       $colorWhite;
        font-size:   15px;
        font-weight: $fontWeightSemiBold;
        line-height: 22px;
        padding:     0;
        margin:      0 0 10px 0;
    }
}

.wrapper
{
    background:    $colorGrayLighter2;
    padding:       14px 14px 0 14px;
    border-radius: 3px;
    margin:        0 0 20px 0;
    transition:    background-color $defaultEffectAnimationSpeed linear,
                   border-color $defaultEffectAnimationSpeed linear;
    position:      relative;
    border-right:  4px solid $colorGrayLighter2;
    user-select:   none;

    &:hover
    {
        background:   $colorGrayLighter4;
        border-color: $colorGrayLighter4;

        .dotListWrapper
        {
            opacity:        1;
            pointer-events: all;
        }
    }

    &:active,
    &.wrapperActive
    {
        background:   $colorGrayLighter2 !important;
        border-color: $colorTurquoise;
    }

    &:last-child
    {
        margin-bottom: 0;
    }
}
