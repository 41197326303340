//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/breakpoints';
@import '../../../styles/zIndexes';

.editButtonWrapper
{
    margin-left:   auto;
    margin-bottom: 20px;
}

.wrapper
{
    position:  relative;
    width:     $profileContentWidth;
    max-width: 100%;

    &.wrapperCenter
    {
        text-align: center;
    }

    &.wrapperEditButtonVisible
    {
        display:   flex;
        flex-flow: row wrap;

        @media (min-width: $screen-md)
        {
            flex-flow: row nowrap;
        }

        h2
        {
            max-width:     100%;
            overflow:      hidden;
            text-overflow: ellipsis;
            white-space:   nowrap;
        }
    }

    &.wrapperLeft
    {
        text-align: left;
    }

    h2
    {
        font-weight: bold;
        font-size:   28px;
        color:       $colorWhite;
        padding:     4px 0 0 0;
        margin:      0 0 30px 0;
    }
}
