//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/zIndexes';

$buttonSize: 30px;

.button
{
    width:         $buttonSize;
    height:        $buttonSize;
    border:        0;
    border-radius: $buttonSize;
    cursor:        pointer;
    background:    $colorTransparent;
    outline:       none;
    transition:    background-color $defaultEffectAnimationSpeed linear;

    &.buttonThemeBig
    {
        height: 50px;
        border: 2px solid $colorTurquoise;
        width:  66px;

        svg
        {
            *
            {
                stroke: $colorTurquoise;
            }
        }
    }

    &.buttonThemeSmall
    {

    }
}

.menu
{
    position:       absolute;
    top:            0;
    z-index:        $zIndexDotListMenu;
    pointer-events: none;
    padding:        5px 0 0 0;
    opacity:        0;
    transition:     opacity $defaultEffectAnimationSpeed linear,
                    top $defaultEffectAnimationSpeed linear;

    &.menuAlignmentLeft
    {
        left: 0;
    }

    &.menuAlignmentRight
    {
        right: 0;
    }

    &.menuThemeBig
    {

    }

    &.menuThemeSmall
    {

    }

    ul
    {
        box-shadow:    0 0 10px 2px rgba(0, 0, 0, 0.5);
        background:    $colorGrayLight2;
        border:        1px solid $colorGrayLighter2;
        border-radius: 3px;
        list-style:    none;
        padding:       0;
        margin:        0;
    }
}

.wrapper
{
    position:   relative;
    display:    inline-block;

    &.wrapperThemeBig
    {

    }

    &.wrapperThemeSmall
    {

    }

    &.showMenuOnClick,
    &.showMenuOnHover
    {
        &:hover
        {
            .button
            {
                background: $colorTurquoise;

                svg
                {
                    *
                    {
                        stroke: $colorGray;
                    }
                }
            }

            .menu
            {
                top:            $buttonSize;
                pointer-events: all;
                opacity:        1;
            }
        }
    }
}
