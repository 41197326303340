//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/fontWeights';

.iconWrapper
{
    width: 20px;

    svg
    {
        *
        {
            stroke: $colorTurquoise;
        }
    }
}

.text
{
    flex: 1;

    &.textUndefined
    {
        color: $colorRedLight;
    }
}

.wrapper
{
    display:        flex;
    flex-direction: row;
    align-items:    center;
    box-sizing:     border-box;

    &.wrapperDisabled
    {
        color: $colorGrayLighter7 !important;

        .iconWrapper
        {
            svg
            {
                *
                {
                    stroke: $colorGrayLighter7;
                }
            }
        }
    }

    &.wrapperBigWhite
    {
        font-size:   15px;
        color:       $colorWhite;
        line-height: 14px;

        .iconWrapper
        {
            width:  20px;
            margin: 0 15px 0 0;
        }
    }

    &.wrapperHugeGray
    {
        font-size:   14px;
        color:       $colorGrayLighter3;
        line-height: 18px;

        .iconWrapper
        {
            width:  25px;
            margin: 0 19px 0 0;

            svg
            {
                *
                {
                    stroke: $colorGrayLighter3;
                }
            }
        }
    }

    &.wrapperSmallGray
    {
        font-size:   10px;
        color:       $colorGrayLighter3;
        line-height: 15px;

        .iconWrapper
        {
            width:  15px;
            margin: 0 8px 0 0;
        }
    }
}
