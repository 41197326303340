//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
@import '../../../styles/colors';
@import '../../../styles/variables';

.versionNumberWrapper
{
    text-align:  left;
    font-size:   10px;
    color:       $colorGrayLighter2;
    cursor:      default;
    max-width:   125px;
    white-space: nowrap;
    overflow:    hidden;
    transition:  color $defaultEffectAnimationSpeed linear,
                 max-width $defaultEffectAnimationSpeed linear;

    &:hover
    {
        color:     $colorTurquoise;
        max-width: 300px;
    }
}

.versionNumber
{
}
