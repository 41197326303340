//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../styles/colors';
@import '../../../styles/fontWeights';
@import '../../../styles/breakpoints';
@import '../../../styles/zIndexes';

.contentWrapper
{
    flex: 1;
}

.labelWrapper
{
    box-sizing:      border-box;
    padding:         0 50px 0 0;
    color:           $colorGrayLighter9;
    font-weight:     $fontWeightMedium;
    font-size:       15px;
    display:         flex;
    flex-direction:  column;
    justify-content: center;
    max-height:      50px;
    margin-bottom:   10px;

    @media (min-width: $screen-md)
    {
        width:         280px;
        padding:       0 20px 0 0;
        margin-bottom: 0;
    }

    span
    {
        color: $colorGrayLighter3;
    }

    .subLabelWrapper
    {
        font-weight: normal;
        color:       $colorGrayLighter3;
        font-size:   14px;
    }
}

.tooltipWrapper
{
    width:           50px;
    display:         flex;
    padding:         15px 0 0 0;
    justify-content: center;
    box-sizing:      border-box;
    margin-right:    -25px;

    @media (min-width: $screen-md)
    {
        margin-right: 0;
    }
}

.tooltipWrapperInner
{
    // This is required to break the outer flex
}

.wrapper
{
    display:   flex;
    flex-flow: column;
    margin:    0 0 20px 0;

    @media (min-width: $screen-md)
    {
        flex-flow: row nowrap;
    }

    &.wrapperNoGapToPreviousFormRow
    {
        margin-top: -20px;
    }

    .formRowInputTooltipWrapper
    {
        flex:      1;
        display:   flex;
        flex-flow: row nowrap;
    }
}
